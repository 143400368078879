define("discourse/plugins/discourse-ezd-integration/discourse/templates/connectors/user-profile-primary/ezd-profile-link", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <p><a href="https://exploitzeroday.com/accounts/{{model.username}}">
    {{fa-icon "external-link"}} Darknet Profile for {{model.username}}</a>
  </p>
  */
  {
    "id": "MEYpa0y9",
    "block": "[[[10,2],[12],[44,[[28,[37,1],null,[[\"model\"],[[28,[32,0],[\"model\"],null]]]]],[[[10,3],[15,6,[29,[\"https://exploitzeroday.com/accounts/\",[52,[30,1,[\"model\"]],[28,[30,1,[\"model\"]],null,null],[28,[32,1],[[30,0],\"model.username\",\"[\\\"The `model` property path was used in the `discourse/plugins/discourse-ezd-integration/discourse/templates/connectors/user-profile-primary/ezd-profile-link.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[12],[1,\"\\n  \"],[1,[28,[35,3],[\"external-link\"],null]],[1,\" Darknet Profile for \"],[1,[30,0,[\"model\",\"username\"]]],[13]],[1]]],[1,\"\\n\"],[13],[1,[28,[32,2],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-ezd-integration/discourse/templates/connectors/user-profile-primary/ezd-profile-link.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"let\",\"hash\",\"if\",\"fa-icon\"]]",
    "moduleName": "discourse/plugins/discourse-ezd-integration/discourse/templates/connectors/user-profile-primary/ezd-profile-link.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});